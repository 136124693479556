import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import api from "../../../service/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  dropDown: {
    verticalAlign: "bottom",
    marginBottom: "20px",
    minWidth: "100px",
  },
  buttonCheck: {
    verticalAlign: "bottom",
    marginBottom: "20px",
    marginLeft: "20px",
    minWidth: "100px",
  },
  drawnumbers: {
    minWidth: "300px",
  },
  spinner: {
    color: "#FFF",
  },
}));

export default function LotteryFormFunction({ setOpenSuccess, setOpenError }) {
  const classes = useStyles();
  const [drawValue, setDrawValue] = React.useState("");
  const [valueConcourseNumber, setValueConcourseNumber] = React.useState("");
  const [radioValue, setRadioValue] = React.useState("");
  const [concourseDate, setConcourseDate] = React.useState("");
  const [concourseNumber, setConcourseNumber] = React.useState("");
  const [hitsCount, setHitsCount] = React.useState("");
  const [hits, setHits] = React.useState("");
  const [showResult, setShowResult] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleDrawChange = (event) => {
    setDrawValue(event.target.value);
  };

  const handleChangeConcourseNumber = (event) => {
    setValueConcourseNumber(event.target.value);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    checkLotteryResult()
      .then((response) => {
        if (response.status === 200) {
          setConcourseDate(response.data.concourseDate);
          setConcourseNumber(response.data.concourseNumber);
          setHitsCount(response.data.hitsCount);
          setHits(response.data.hits);
          setShowResult(true);
          setLoading(false);
          setOpenSuccess(true);
        } else {
          setLoading(false);
          setShowResult(false);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowResult(false);
        setOpenError(true);
        console.log(error);
      });
  };

  async function checkLotteryResult() {
    var drawValueStr = drawValue;
    drawValueStr = drawValueStr.replaceAll("-", ",");
    drawValueStr = drawValueStr.replaceAll(".", ",");
    if (valueConcourseNumber) {
      var lotteryCommand = {
        lotterykind: radioValue,
        drawnumbers: drawValueStr.split(","),
        concoursenumber: valueConcourseNumber,
      };
    } else {
      var lotteryCommand = {
        lotterykind: radioValue,
        drawnumbers: drawValueStr.split(","),
      };
    }
    var res = await api.post("/lottery/", lotteryCommand);
    return await res;
  }

  const rendeResult = () => {
    if (showResult) {
      return (
        <div>
          <strong>Data do sorteio:</strong> {concourseDate} -{" "}
          <strong>Concurso:</strong>
          {concourseNumber} - <strong>Acertos:</strong> {hitsCount}{" "}
          <strong>Números:</strong> {hits.join()}{" "}
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="outlined-multiline-flexible"
            className={classes.drawnumbers}
            label="Números jogados"
            value={drawValue}
            onChange={handleDrawChange}
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Número do concurso"
            value={valueConcourseNumber}
            onChange={handleChangeConcourseNumber}
            variant="outlined"
          />
          <Select
            className={classes.dropDown}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <MenuItem value={1}>Lotofácil</MenuItem>
            <MenuItem value={2}>Megasena</MenuItem>
            <MenuItem value={3}>Quina</MenuItem>
            <MenuItem value={4}>Lotomania</MenuItem>
          </Select>
          <Button
            className={classes.buttonCheck}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            {loading ? (
              <CircularProgress size={24} className={classes.spinner} />
            ) : (
              "Checar"
            )}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12}>
          {rendeResult()}
        </Grid>
      </Grid>
    </form>
  );
}
