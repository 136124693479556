import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import HeartTable from "./../../components/Table/HeartTable";
import HeartForm from "./../../components/Form/HeartForm";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import api from './../../service/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFE9CC",
    color: "rgba(0, 0, 0, 0.54)",
  },
  appBar: {
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HeartRate({parentRates, setParentRates, setOpenSuccess, setOpenError}) {
  const classes = useStyles();
  const [averages, setAverages] = useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    loadAverages();
  }, [parentRates]);

  async function loadAverages() {

    const response = await api.get('/averages');
    setAverages(response.data);
    console.log(averages);
    setLoading(false);
  }

  function formatPreassure(preassure) {
    var floatPreassure = parseFloat(preassure); 
   
    return floatPreassure.toFixed(2);
  }

  return (
    <div className={classes.root} id="heartAverage">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <HeartTable parentRates={parentRates} setParentRates={setParentRates} setOpenSuccess={setOpenSuccess} setOpenError={setOpenError}  />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <HeartForm parentRates={parentRates} setParentRates={setParentRates} setOpenSuccess={setOpenSuccess} setOpenError={setOpenError}   />
            </Paper>
          </Grid>
          {loading ? <CircularProgress size={24} /> :
          <>
          <Grid container xs={12} sm={12}>
            <Grid item xs={3} sm={3}>
              <Paper className={classes.paper}>
                <Typography variant="h6" component="h2">
                  Bebi Ontem
                </Typography>
                <Divider></Divider>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.drinkedYesterday?.sistolica)}<br />
                </Typography>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.drinkedYesterday?.diastolica)}<br />
                  {averages?.drinkedYesterday?.total}<br />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Paper className={classes.paper}>
                <Typography variant="h6" component="h2">
                  Sem beber
                </Typography>
                <Divider></Divider>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.withoutDrink?.sistolica)}<br />
                </Typography>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.withoutDrink?.diastolica)}<br />
                  {averages?.withoutDrink?.total}<br />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Paper className={classes.paper}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }} component="h2">
                  Comi Besteira Ontem
                </Typography>
                <Divider></Divider>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.eatedTrash?.sistolica)}<br />
                </Typography>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.eatedTrash?.diastolica)}<br />
                  {averages?.eatedTrash?.total}<br />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Paper className={classes.paper}>
              <Typography variant="h6" component="h2">
                  Exercícios Ontem
                </Typography>
                <Divider></Divider>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.workoutYesterday?.sistolica)}<br />
                </Typography>
                <Typography variant="h4" component="h2">
                  {formatPreassure(averages?.workoutYesterday?.diastolica)}<br />
                  {averages?.workoutYesterday?.total}<br />
                </Typography>
              </Paper>
            </Grid>
          </Grid>
           <Grid container xs={12} sm={12}>
           <Grid item xs={3} sm={3}>
             <Paper className={classes.paper}>
               <Typography variant="h6" component="h2">
                 Segunda
               </Typography>
               <Divider></Divider>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.monday?.sistolica)}<br />
               </Typography>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.monday?.diastolica)}<br />
                 {averages?.monday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
           <Grid item xs={3} sm={3}>
             <Paper className={classes.paper}>
               <Typography variant="h6" component="h2">
                 Terça
               </Typography>
               <Divider></Divider>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.tuesday?.sistolica)}<br />
               </Typography>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.tuesday?.diastolica)}<br />
                 {averages?.tuesday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
           <Grid item xs={3} sm={3}>
             <Paper className={classes.paper}>
               <Typography variant="subtitle1" style={{ fontWeight: 600 }} component="h2">
                 Quarta
               </Typography>
               <Divider></Divider>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.wednesday?.sistolica)}<br />
               </Typography>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.wednesday?.diastolica)}<br />
                 {averages?.wednesday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
           <Grid item xs={3} sm={3}>
             <Paper className={classes.paper}>
             <Typography variant="h6" component="h2">
                 Quinta
               </Typography>
               <Divider></Divider>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.thrusday?.sistolica)}<br />
               </Typography>
               <Typography variant="h4" component="h2">
                 {formatPreassure(averages?.thrusday?.diastolica)}<br />
                 {averages?.thrusday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
         </Grid>
         <Grid container xs={12} sm={12}>
           <Grid item xs={4} sm={4}>
             <Paper className={classes.paper}>
               <Typography variant="h5" style={{ fontWeight: 600 }} component="h2">
                 Sexta
               </Typography>
               <Divider></Divider>
               <Typography variant="h3" component="h2">
                 {formatPreassure(averages?.friday?.sistolica)}<br />
               </Typography>
               <Typography variant="h3" component="h2">
                 {formatPreassure(averages?.friday?.diastolica)}<br />
                 {averages?.friday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
           <Grid item xs={4} sm={4}>
             <Paper className={classes.paper}>
               <Typography variant="h5" style={{ fontWeight: 600 }} component="h2">
                 Sábado
               </Typography>
               <Divider></Divider>
               <Typography variant="h3" component="h2">
                 {formatPreassure(averages?.saturday?.sistolica)}<br />
               </Typography>
               <Typography variant="h3" component="h2">
                 {formatPreassure(averages?.saturday?.diastolica)}<br />
                 {averages?.saturday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
           <Grid item xs={5} sm={4}>
             <Paper className={classes.paper}>
               <Typography variant="h5" style={{ fontWeight: 600 }} component="h2">
                 Domingo
               </Typography>
               <Divider></Divider>
               <Typography variant="h3" component="h2">
                 {formatPreassure(averages?.sunday?.sistolica)}<br />
               </Typography>
               <Typography variant="h3" component="h2">
                 {formatPreassure(averages?.sunday?.diastolica)}<br />
                 {averages?.sunday?.total}<br />
               </Typography>
             </Paper>
           </Grid>
         </Grid>
         </>
          }
        </Grid>
      </Grid>
    </div>
  );
}
