import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import api from "./../../service/api";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HeartFormFunction({parentRates, setParentRates, setOpenSuccess, setOpenError}) {
  const classes = useStyles();
  const [sistolicaRate, setSistolicaRate] = React.useState("");
  const [diastolicaRate, setDiastolicaRate] = React.useState("");
  const [withoutDrinkChecked, setWithoutDrinkChecked] = React.useState(false);
  const [drinkedYesterdayChecked, setDrinkedYesterday] = React.useState(false);
  const [ateTrashChecked, setAteTrash] = React.useState(false);
  const [workoutYesterdayChecked, setWorkoutYesterday] = React.useState(false);
  const [beforeMedicineChecked, setBeforeMedicine] = React.useState(false);
  
  const handleChangeSistolicaRate = (event) => {
    setSistolicaRate(event.target.value);
  };

  const handleChangeDiastolicaRate = (event) => {
    setDiastolicaRate(event.target.value);
  };

  const handleSubmit = (event) => {
    createRate()
      .then((response) => {
        if (response.status === 200) {
          setParentRates(parentRates +1);
          setOpenSuccess(true);
          console.log("Salvo com sucesso!!!");
        } else {
          setOpenError(true);
          console.log(response);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
      });
  };

  const handleChangeWithoutDrink = (event) => {
    setWithoutDrinkChecked(event.target.checked);
  };

  const handleChangeDrinkedYesterday = (event) => {
    setDrinkedYesterday(event.target.checked);
  };

  const handleChangeAteTrash = (event) => {
    setAteTrash(event.target.checked);
  };

  const handleChangeWorkoutYesterday = (event) => {
    setWorkoutYesterday(event.target.checked);
  };

  const handleChangeBeforeMedicine = (event) => {
    setBeforeMedicine(event.target.checked);
  };

  async function createRate() {
    var rateCommand = {
      rates: [sistolicaRate, diastolicaRate],
      obs: [withoutDrinkChecked, drinkedYesterdayChecked, ateTrashChecked, workoutYesterdayChecked, beforeMedicineChecked],
    };
    console.log(JSON.stringify(rateCommand));
    var res = await api.post("/rates/", rateCommand);
    return await res;
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="outlined-basic"
            label="Sistólica"
            variant="outlined"
            onChange={handleChangeSistolicaRate}
            value={sistolicaRate}
            type="number"
          />
          <TextField
            id="outlined-basic"
            label="Diastólica"
            variant="outlined"
            onChange={handleChangeDiastolicaRate}
            value={diastolicaRate}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="chkWithoutDrink"
                checked={withoutDrinkChecked}
                onChange={handleChangeWithoutDrink}
              />
            }
            label="Sem beber"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="chkDrinkedYesterday"
                checked={drinkedYesterdayChecked}
                onChange={handleChangeDrinkedYesterday}
              />
            }
            label="Bebi ontem"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="chkAteTrash"
                checked={ateTrashChecked}
                onChange={handleChangeAteTrash}
              />
            }
            label="Comi besteira"
          />
           <FormControlLabel
            control={
              <Checkbox
                name="chkWorkoutYesterday"
                checked={workoutYesterdayChecked}
                onChange={handleChangeWorkoutYesterday}
              />
            }
            label="Atividade física ontem"
          />
           <FormControlLabel
            control={
              <Checkbox
                name="chkBeforeMedicine"
                checked={beforeMedicineChecked}
                onChange={handleChangeBeforeMedicine}
              />
            }
            label="Antes de tomar remédio"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Salvar
          </Button>
        </Grid>
      </Grid>
     
    </form>
  );
}
