import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import AnnotationIcon from "@material-ui/icons/Assignment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import HeartRate from "../../pages/HeartRate/HeartRate";
import Annotations from "../../pages/Anottations/Annotations";
import Countdown from "../../pages/Countdown/Countdown";
import Lottery from "../../pages/Lottery/Lottery";
import Bills from "../../pages/Bills/Bills";
import Boxing from "../../pages/Boxing/Boxing";
import Pmt from "../../pages/Pmt/Pmt";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import PaidIcon from "@mui/icons-material/Paid";
import Alarm from "@mui/icons-material/Alarm";
import SoccerIcon from "@mui/icons-material/SportsSoccer";
import BoxingIcon from "@mui/icons-material/SportsMma";
import useToken from "../../useToken";
import Hidden from "@material-ui/core/Hidden";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#E58300",
  },
  appBar: {
    alignItems: "center",
  },
}));

export default function TabBarFunction() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [rates, setRates] = useState([]);
  const [billState, setBillState] = useState([]);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const { token, setToken } = useToken();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const signout = (event) => {
    setToken({ token: null });
    window.location.reload();
  };

  return (
    <div className={classes.root} id="tabBar">
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Pressão" icon={<FavoriteIcon />} {...a11yProps(0)} />
          <Hidden smDown>
            <Tab label="Contas" icon={<PaidIcon />} {...a11yProps(1)} />
          </Hidden>
          <Tab label="Anotações" icon={<AnnotationIcon />} {...a11yProps(2)} />
          <Hidden smDown>
            {" "}
            <Tab label="PMT" icon={<SoccerIcon />} {...a11yProps(3)} />
          </Hidden>
          <Tab
            label="Loterias"
            icon={<AccountBalanceIcon />}
            {...a11yProps(4)}
          />
          <Hidden smDown>
            <Tab
              label="Contagem Regressiva"
              icon={<Alarm />}
              {...a11yProps(5)}
            />
          </Hidden>
          <Tab label="Boxe" icon={<BoxingIcon />} {...a11yProps(3)} />
          <Tab
            label="Sair"
            onClick={() => signout()}
            icon={<ShoppingBasket />}
            {...a11yProps(6)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <HeartRate
          parentRates={rates}
          setParentRates={setRates}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Bills
          parentBills={billState}
          setParentBills={setBillState}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Annotations setOpenError={setOpenError} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Pmt setOpenSuccess={setOpenSuccess} setOpenError={setOpenError} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Lottery setOpenSuccess={setOpenSuccess} setOpenError={setOpenError} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Countdown setOpenError={setOpenError} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Boxing setOpenError={setOpenError} />
      </TabPanel>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          Salvo!!!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert onClose={handleCloseError} severity="error">
          Error!!!!
        </Alert>
      </Snackbar>
    </div>
  );
}
