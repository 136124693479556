import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import api from "../../../service/api";
import IconButton from "@material-ui/core/IconButton";
import IconDelete from "@material-ui/icons/Delete";
import dayjs from "dayjs";

const columns = [
  { id: "id", label: "id", minWidth: 35 },
  { id: "name", label: "Nome", minWidth: 35 },
  { id: "came", label: "Compareceu", minWidth: 25 },
  { id: "enemy", label: "Adversário", minWidth: 25 },
  { id: "popcorn", label: "Pipocou", minWidth: 25 },
];

function createData(name, id, came, enemy, popcorn) {
  return { id, name, came, enemy, popcorn };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {},
});

export default function PmtInnerTableRankingFunction({
  setOpenSuccess,
  setOpenError,
  innerTable,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [players, setPlayers] = useState([]);
  const [rows, setRows] = useState([]);
  const [deletedOpen, setDeletedOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [came, setCame] = useState([]);
  const [enemy, setEnemy] = useState([]);
  const [popcorn, setPopCorn] = useState([]);
  const [gameDate, setGameDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());

  useEffect(() => {
    loadPlayers();
  }, []);

  const tempRows = [];
  async function loadPlayers() {
    const response = await api.get("/pmt");
    console.log(response.data);
    setPlayers(response.data);
    response.data.forEach(function (item, i) {
      tempRows.push(
        createData(
          item.name,
          item.id,
          item.year23,
          item.scheduledGame,
          item.popcorn
        )
      );
    });
    setRows(tempRows);
    setLoading(false);
  }

  async function deleteRow(e) {
    await api
      .delete(`/pmt/${e.id}`)
      .then((response) => {
        if (response.status === 200) {
          loadPlayers();
          setLoading(false);
          setDeletedOpen(true);
        } else {
          console.log(response);
          setLoading(false);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    hidden={column.label === "id"}
                    style={{
                      minWidth: column.minWidth,
                      padding: "10px",
                      display: column.label === "id" ? "none" : "table-cell",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (value > 13) {
                          value = <strong>{value}</strong>;
                        }

                        return (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                display:
                                  column.label === "id" ? "none" : "table-cell",
                              }}
                            >
                              {value}
                            </TableCell>
                          </>
                        );
                      })}
                      <TableCell>
                        <IconButton
                          onClick={() => deleteRow(row)}
                          color="secondary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <IconDelete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </>
  );
}
