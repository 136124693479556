import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CountdownTimer from "../../components/Countdown/CountdownTimer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFE9CC",
    color: "rgba(0, 0, 0, 0.54)",
  },
  appBar: {
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export default function CountdownFunction({ setOpenError }) {
  const classes = useStyles();
  const countDownDate = new Date(2022, 9, 16).getTime();
  const THREE_DAYS_IN_MS = new Date(2022, 8, 16).getTime();
  const dateTimeAfterThreeDays = THREE_DAYS_IN_MS;
  debugger;
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return (
    <Box width="100%" display="inline-block">
      <Paper width="100%" className={classes.root}>
        {<CountdownTimer targetDate={dateTimeAfterThreeDays} />}
      </Paper>
    </Box>
  );
}
