import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import api from "../../service/api";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HeartFormFunction({setOpenSuccess, setOpenError}) {
  const classes = useStyles();
  const [player, setPlayer] = React.useState("");
  
  const handleChangePlayer = (event) => {
    setPlayer(event.target.value);
  };

  const handleSubmit = (event) => {
    createPlayer()
      .then((response) => {
        if (response.status === 200) {
          setOpenSuccess(true);
          console.log("Salvo com sucesso!!!");
        } else {
          setOpenError(true);
          console.log(response);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
      });
  };

  async function createPlayer() {
    var playerCommand = {
      name: player
    };
    console.log(JSON.stringify(playerCommand));
    var res = await api.post("/pmt/", playerCommand);
    return await res;
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="outlined-basic"
            label="Atleta"
            variant="outlined"
            onChange={handleChangePlayer}
            value={player}
            type="text"
          />
         
        </Grid>    
        <Grid item xs={12} sm={12}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Salvar
          </Button>
        </Grid>
      </Grid>
     
    </form>
  );
}
