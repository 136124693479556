import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import api from "./../../service/api";

const Lotteries = [
  { value: 1, label: "Lotofácil" },
  { value: 2, label: "Mega-Sena" },
  { value: 3, label: "Quina" },
  { value: 4, label: "Lotomania" },
];

function NumberButtons({ setOpenSuccess, setOpenError, loadMyBets }) {
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [selectedLottery, setSelectedLottery] = useState("");
  const [teimosinha, setTeimosinha] = useState("");
  const [concurso, setConcurso] = useState("");
  const [loading, setLoading] = React.useState(false);

  const handleButtonClick = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleSaveClick = () => {
    if (selectedLottery) {
      enviarNumeros(selectedNumbers, selectedLottery)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setOpenSuccess(true);
            loadMyBets();
            console.log(response);
          } else {
            console.log(response);
            setLoading(false);
            setOpenError(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setOpenError(true);
        });
    } else {
      console.log("Selecione uma loteria antes de salvar.");
    }
  };

  async function enviarNumeros(numbers, lotteryNumber) {
    console.log(
      `Enviando números para a loteria ${lotteryNumber}: ${numbers.join(", ")}`
    );
    console.log(`Teimosinha: ${teimosinha}`);
    var lotteryCommand = {
      hint: numbers.join(", "),
      concourse: lotteryNumber,
      lotterykind: selectedLottery,
      stubborn: teimosinha,
    };
    debugger;
    return await api.post("/lottery/mybets", lotteryCommand);
  }

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {Array.from({ length: 50 }, (_, index) => index + 1).map((number) => (
          <Button
            key={number}
            variant={
              selectedNumbers.includes(number) ? "contained" : "outlined"
            }
            color={selectedNumbers.includes(number) ? "secondary" : "primary"}
            onClick={() => handleButtonClick(number)}
            style={{ margin: "5px" }}
          >
            {number}
          </Button>
        ))}
      </div>

      <Select
        labelId="lottery-label"
        id="lottery-select"
        value={selectedLottery}
        onChange={(e) => setSelectedLottery(e.target.value)}
        style={{ margin: "5px" }}
      >
        {Lotteries.map((lottery) => (
          <MenuItem key={lottery.value} value={lottery.value}>
            {lottery.label}
          </MenuItem>
        ))}
      </Select>

      <TextField
        id="teimosinha"
        label="Teimosinha"
        value={teimosinha}
        onChange={(e) => setTeimosinha(e.target.value)}
        variant="outlined"
        margin="dense"
        type="number"
        style={{ margin: "10px" }}
      />
      <TextField
        id="concurso"
        label="Número do concurso"
        value={concurso}
        onChange={(e) => setConcurso(e.target.value)}
        variant="outlined"
        margin="dense"
        type="number"
        style={{ margin: "10px" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveClick}
        style={{ margin: "10px" }}
      >
        Salvar
      </Button>
    </div>
  );
}

export default NumberButtons;
