import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import api from './../../service/api';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useToken from '../../useToken';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function AnnotationFunction({ setOpenError }) {
  const { token, setToken } = useToken();

  const classes = useStyles();
  const [value, setValue] = React.useState('Controlled');
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    loadText();
  }, []);

  async function loadText() {
    let config = {
      headers: {
        'x-access-token': token,
      }
    }
    const response = await api.get('/annotation', config);
    setValue(response.data.text);
    setLoading(false);
  }

  const handleChange = (event) => {
    setValue(event.target.value);
    updateText()
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
      });

  };

  async function updateText() {
    let config = {
      headers: {
        'x-access-token': token,
      }
    }
    var res = await api.put("/annotation/", { text: value }, config);
    return await res;
  }

  return (
    <Box width="100%" display="inline-block">
      <Paper width="100%" className={classes.root}>
        {loading ? <CircularProgress size={24} /> :
          <TextField
            style={{ minWidth: '800px' }}
            id="outlined-multiline-static"
            label="Anotações"
            multiline
            rows={40}
            value={value}
            onChange={handleChange}
            variant="outlined"
          />
        }
      </Paper>
    </Box>
  );
}
