import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MuiAlert from "@material-ui/lab/Alert";
import api from './../../service/api';
import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LocalBarOutlinedIcon from '@material-ui/icons/LocalBarOutlined';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import { Fragment } from 'react';

const columns = [
  { id: 'diastolica', label: 'Diastolica', minWidth: 35 },
  { id: 'sistolica', label: 'Sistolica', minWidth: 35 },
  { id: 'date', label: 'Data', minWidth: 50 },
  {
    id: 'obs',
    label: 'Observação',
    minWidth: 100,
    align: 'right',
  },
];

function createData(diastolica, sistolica, date, obs, id) {
  return { diastolica, sistolica, date, obs, id };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
  },
});

export default function HeartTableFunction({ parentRates, setParentRates,  setOpenSuccess, setOpenError}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rates, setRates] = useState([]);
  const [rows, setRows] = useState([]);
  const [deletedOpen, setDeletedOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    loadRates();
  }, [parentRates]);

  const tempRows = [];
  async function loadRates() {

    const response = await api.get('/rates');
    setRates(response.data);
    response.data.forEach(function (item, i) {
      tempRows.push(createData(item.rates[0], item.rates[1], item.date, item.obs, item._id));
    })
    setRows(tempRows);
    setLoading(false);
  }

  async function deleteRow(e) {
    await api.delete(`/rates/${e.id}`).then(response => {
      if (response.status === 200) {
        loadRates();
        setLoading(false);
        setDeletedOpen(true);
      } else {
        console.log(response);
        setLoading(false);
      }
    }).catch(error => {
      setOpenError(true)
      console.log(error);
      setLoading(false);
    });
  }

  function getObs(e) {
    let components = [];
    if (e[0]) {
      components.push(<Tooltip title="Sem beber">
        <LocalBarOutlinedIcon />
      </Tooltip>);
    }
    if (e[1]) {
      components.push(<Tooltip title="Bebi Ontem">
        <LocalBarIcon />
      </Tooltip>);
    }
    if (e[2]) {
      components.push(<Tooltip title="Comi besteira">
        <LocalPizzaIcon />
      </Tooltip>);
    }
    if (e[3]) {
      components.push(<Tooltip title="Atividade física Ontem">
        <FitnessCenterIcon />
      </Tooltip>);
    }
    if (e[4]) {
      components.push(<Tooltip title="Antes do remédio">
        <MedicationOutlinedIcon />
      </Tooltip>);
    }
    return components;
  }

  function formatDate(stringDate) {
    var date = new Date(stringDate);
    return date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear()+" "+ date.getHours() +":"+date.getMinutes()+":"+date.getSeconds();
  }

  return (

    <Paper className={classes.root}>
      {loading ? <CircularProgress size={24} /> :
        <Fragment>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      hidden={column.label === "id"}
                      style={{ minWidth: column.minWidth, padding: "10px" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {

                        let value = row[column.id];
                        if (column.id == "diastolica" && value > 13) {
                          value = <strong>{value}</strong>;
                        }
                        if (column.id == "sistolica" && value > 9) {
                          value = <strong>{value}</strong>;
                        }
                        if (column.id == "date") {
                          value = formatDate(value);
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'obs' && value ? getObs(value) : value}

                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <IconButton onClick={() => deleteRow(row)} color="secondary" aria-label="upload picture" component="span">
                          <IconDelete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      }
    </Paper>
  );
}
