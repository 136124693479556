import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MuiAlert from "@material-ui/lab/Alert";
import api from './../../service/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import useToken from '../../useToken';
import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const columns = [
    { id: 'bill', label: 'Conta', minWidth: 35 },
    { id: 'dueDate', label: 'Vencimento', minWidth: 35 },
    { id: 'paid', label: 'Pago', minWidth: 50 },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
    },
});

export default function HeartTableFunction({ parentBills, setParentBills, setOpenSuccess, setOpenError }) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [billsState, setBills] = useState([]);
    const [paids, setPaids] = useState({ [0]: false });
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const { token, setToken } = useToken();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCloseError = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenError(false);
    };

    useEffect(() => {
        loadBills();
    }, [parentBills]);

    const tempRows = [];
    const tempPaids = [];
    async function loadBills() {

        let config = {
            headers: {
                'x-access-token': token,
            }
        }

        try {
            const response = await api.get('/bills', config);

            var billsData = response.data;
            console.log(`bills ${billsData}`);
            billsData.forEach(function (item, i) {
                tempRows.push(createRowsData(item.bill, item.dueDate, item.paid, item._id));
                tempPaids.push(createPaidsData(item._id, item.paid));
            })
            console.log(`tempPaids ${tempPaids}`);
            console.log(`tempRows ${tempRows}`);
            setPaids(tempPaids);
            setRows(tempRows);
            setBills(billsData);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function deleteRow(e) {

        let config = {
            headers: {
                'x-access-token': token,
            }
        }
        await api.delete(`/bills/${e.id}`, config).then(response => {
            if (response.status === 200) {
                loadBills();
                setLoading(false);
            } else {
                console.log(response);
                setLoading(false);
            }
        }).catch(error => {
            setOpenError(true)
            console.log(error);
            setLoading(false);
        });
    }

    function createPaidsData(id, paid) {
        return { id, paid };
    }

    function createRowsData(bill, dueDate, paid, id) {
        return { bill, dueDate, paid, id };
    }


    async function handleCheckBill(row, e) {
        let config = {
            headers: {
              'x-access-token': token,
            }
          }

        var paidsTemp = [...paids];
        var paidTemp = paidsTemp.find(x => x.id === row.id).paid;

        if (paidTemp) {
            paidsTemp.find(x => x.id === row.id).paid = false;
            paidTemp = false;
        } else {
            paidsTemp.find(x => x.id === row.id).paid = true;
            paidTemp = true;
        }

        setPaids(paidsTemp);

        var paidCommand = {
            paid: paidTemp
          };
        await api.put(`/bills/${row.id}`, paidCommand, config).then(response => {
            if (response.status === 200) {
                loadBills();
                setLoading(false);
            } else {
                console.log(response);
                setLoading(false);
            }
        }).catch(error => {
            setOpenError(true)
            console.log(error);
            setLoading(false);
        });
    }

    function getObs(row) {
        console.log(`row ${JSON.stringify(row)}`);
        let foundedPaid = paids.find(x => x.id === row.id);
        if (foundedPaid) {
            return <Checkbox checked={foundedPaid.paid} onChange={() => handleCheckBill(row)} color="success" />
        }
    }

    return (

        <Paper className={classes.root}>
            {loading ? <CircularProgress size={24} /> :
                <Fragment>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            hidden={column.label === "id"}
                                            style={{ minWidth: column.minWidth, padding: "10px" }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                let value = row[column.id];
                                                if (column.id == "bill") {
                                                    value = <strong>{value}</strong>;
                                                }
                                                if (column.id == "dueDate") {
                                                    value = <strong>{value}</strong>;
                                                }
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'paid' ? getObs(row) : value}

                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <IconButton onClick={() => deleteRow(row)} color="secondary" aria-label="upload picture" component="span">
                                                    <IconDelete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Fragment>
            }
        </Paper>
    );
}
