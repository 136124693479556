import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Box } from "@mui/material";
import oneBell from "../../sounds/one_bell.wav";
import twoBells from "../../sounds/two_bells.wav";
import finishBell from "../../sounds/three_bells.wav";
import countdown from "../../sounds/countdown_10.wav";
import letsGo from "../../sounds/lets_go.wav";

let audio = new Audio(oneBell);
let audioTwoBells = new Audio(twoBells);
let audioFinishBell = new Audio(finishBell);
let audioCountDown = new Audio(countdown);
let audioLetsGo = new Audio(letsGo);

const BoxingTimer = () => {
  const [phases, setPhases] = useState([]);
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [totalWorkout, setTotalWorkout] = useState(0);
  const [totalRounds, setTotalRounds] = useState(0);
  const [currentRound, setCurrentRound] = useState(0);

  const playBell = () => {
    audio.play();
  };

  const playCountDown = () => {
    audioCountDown.play();
  };

  const playFinishBell = () => {
    audioFinishBell.play();
  };

  const playTwoBells = () => {
    audioTwoBells.play();
  };

  const playMiddle = () => {
    audioLetsGo.play();
  };

  useEffect(() => {
    if (isRunning && timeRemaining > 0) {
      if (
        Math.round(phases[currentPhaseIndex].seconds / 2) == timeRemaining &&
        phases[currentPhaseIndex].label === "Round"
      ) {
        playMiddle();
      }

      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerInterval);
    } else if (isRunning && timeRemaining === 0) {
      // Fase atual terminou, passa para a próxima
      setIsRunning(false);
      if (phases[currentPhaseIndex].label === "Round") {
        setCurrentRound((prevIndex) => prevIndex + 1);
        if (currentPhaseIndex + 1 == phases.length) {
          playFinishBell();
        } else {
          playTwoBells(); // Toca dois sinos no final do round
        }
      }
      setCurrentPhaseIndex((prevIndex) => prevIndex + 1);

      // Se ainda houver fases, inicia a próxima
      if (currentPhaseIndex < phases.length - 1) {
        setIsRunning(true);
        setTimeRemaining(phases[currentPhaseIndex + 1].seconds);
        if (phases[currentPhaseIndex + 1].label === "Round") {
          playBell(); // Toca um sino no início do próximo round
        }
      }
    }
  }, [
    isRunning,
    timeRemaining,
    currentPhaseIndex,
    phases,
    playBell,
    playFinishBell,
  ]);

  const addPhase = (duration, label) => {
    if (phases.length == 0) {
      setPhases([{ duration: 10, seconds: 10, label: "Warm-up" }]);
    }
    setPhases((prevPhases) => [
      ...prevPhases,
      { duration, seconds: duration, label },
    ]);
    setTotalWorkout((prevTotal) => prevTotal + duration);
    if (label === "Round") {
      setTotalRounds((prevTotal) => prevTotal + 1);
    }
  };

  function secondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  const startTimer = () => {
    if (phases.length > 0 && !isRunning) {
      setIsRunning(true);
      setTimeRemaining(phases[currentPhaseIndex].seconds);
      if (phases[currentPhaseIndex].label === "Round") {
        playBell(); // Toca um sino no início do timer
      }
      if (phases[currentPhaseIndex].label === "Warm-up") {
        playCountDown(); // Toca um sino no início do timer
      }
    }
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning(false);
    setCurrentPhaseIndex(0);
    setTimeRemaining(0);
  };

  function addDefaultTrainning() {
    setPhases([
      { duration: 10, seconds: 10, label: "Warm-up" },
      { duration: 300, seconds: 300, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 180, seconds: 180, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 60, seconds: 60, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 60, seconds: 60, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 60, seconds: 60, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 60, seconds: 60, label: "Round" },
      { duration: 60, seconds: 60, label: "Rest" },
      { duration: 30, seconds: 30, label: "Round" },
      { duration: 30, seconds: 30, label: "Rest" },
      // Adicione outras fases conforme necessário
    ]);
    setTotalWorkout(3000); // Ajuste conforme a duração total do treino padrão
    setTotalRounds(14); // Ajuste conforme o número total de rounds do treino padrão
  }

  const predefinedDurations = [
    { duration: 30, label: "Rest", color: "error" },
    { duration: 60, label: "Rest", color: "error" },
    { duration: 60, label: "Round", color: "success" },
    { duration: 30, label: "Round", color: "success" },
    { duration: 180, label: "Round", color: "success" },
    { duration: 300, label: "Round", color: "success" },
  ];

  return (
    <Grid container xs={12} sm={12} spacing={1}>
      <Grid item xs={4} sm={4}>
        <Paper>
          <Typography variant="h4" gutterBottom>
            Boxing Timer
          </Typography>
          <Button
            key="defaultTrainningKey"
            variant="outlined"
            color="success"
            style={{ margin: "15px" }}
            onClick={addDefaultTrainning}
          >
            Treino Padrão
          </Button>
          {predefinedDurations.map((phase, index) => (
            <Button
              key={index}
              variant="outlined"
              color={phase.color ?? "success"}
              style={{ margin: "15px" }}
              onClick={() => addPhase(phase.duration, phase.label)}
            >
              {phase.duration} seconds {phase.label}
            </Button>
          ))}
          <Box mb={2}>
            <Button
              variant="contained"
              style={{ margin: "15px" }}
              onClick={startTimer}
            >
              Start
            </Button>
            <Button
              variant="contained"
              style={{ margin: "15px" }}
              onClick={stopTimer}
            >
              Stop
            </Button>
            <Button
              variant="contained"
              style={{ margin: "15px" }}
              onClick={resetTimer}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Paper>
          <Typography fontWeight="bold" variant="h3">
            Round Atual: {currentRound + 1} de {totalRounds}
          </Typography>
          <Typography fontWeight="bold" variant="h3">
            Tempo: {timeRemaining}
          </Typography>
          <Typography variant="h6">Phases:</Typography>
          <ul>
            {phases.map((phase, index) => (
              <li key={index}>
                {index + 1} - {phase.duration} seconds {phase.label}
              </li>
            ))}
          </ul>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Paper>
          <Typography variant="h6" component="h6">
            Tempo Total
          </Typography>
          {secondsToMinutes(totalWorkout)}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BoxingTimer;
