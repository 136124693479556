import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LotteryKindEnum } from "./../../../enum/LotteryKindEnum";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import api from "../../../service/api";

const useStyles = makeStyles((theme) => ({
  cards: {
    marginLeft: "6px",
  },
}));

export default function MyBetsTableFunction({ setOpenError }) {
  const classes = useStyles();
  const [mostEverValue, setMostEverValue] = React.useState("");
  const [mostYearValue, setMostYearValue] = React.useState("");
  const [mostMonthValue, setMostMonthValue] = React.useState("");
  const [mostLast365Value, setMostLast365Value] = React.useState("");
  const [mostLast30Value, setMostLast30Value] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [radioValue, setRadioValue] = React.useState("");

  useEffect(() => {
    initializeMost();
  }, []);

  async function initializeMost(kind) {
    try {
      if (kind === undefined) {
        kind = LotteryKindEnum.LOTOFACIL;
      }

      var newRows = loadMost(kind)
        .then((response) => {
          console.log(response);
          setLoading(false);
          setMostEverValue(
            JSON.parse(response.data[0].mostlucky).LotteryMostEver
          );
          setMostYearValue(
            JSON.parse(response.data[0].mostlucky).LotteryMostYear
          );
          setMostMonthValue(
            JSON.parse(response.data[0].mostlucky).LotteryMostMonth
          );
          setMostLast365Value(
            JSON.parse(response.data[0].mostlucky).LotteryLast365Days
          );
          setMostLast30Value(
            JSON.parse(response.data[0].mostlucky).LotteryLast30Days
          );
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setOpenError(true);
        });
    } catch (error) {
      console.error("Erro ao carregar apostas:", error);
      setLoading(false);
    }
  }

  async function loadMost(kind) {
    var res = await api.get("/lottery/most/" + kind);
    return res;
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    initializeMost(event.target.value);
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Grid container xs={12} sm={12} spacing={1}>
          <Grid item xs={12} sm={12}>
            <Select
              className={classes.dropDown}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <MenuItem value={1}>Lotofácil</MenuItem>
              <MenuItem value={2}>Megasena</MenuItem>
              <MenuItem value={3}>Quina</MenuItem>
              <MenuItem value={4}>Lotomania</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h6">
              Sempre
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostEverValue[0]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostEverValue[0]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostEverValue[1]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostEverValue[1]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostEverValue[2]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostEverValue[2]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostEverValue[3]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostEverValue[3]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostEverValue[4]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostEverValue[4]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h6">
              Este ano
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostYearValue[0]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostYearValue[0]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostYearValue[1]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostYearValue[1]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostYearValue[2]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostYearValue[2]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostYearValue[3]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostYearValue[3]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostYearValue[4]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostYearValue[4]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h6">
              Este mês
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostMonthValue[0]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostMonthValue[0]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostMonthValue[1]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostMonthValue[1]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostMonthValue[2]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostMonthValue[2]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostMonthValue[3]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostMonthValue[3]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostMonthValue[4]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostMonthValue[4]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h6">
              Ultimos 365 dias
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast365Value[0]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast365Value[0]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast365Value[1]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast365Value[1]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast365Value[2]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast365Value[2]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast365Value[3]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast365Value[3]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast365Value[4]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast365Value[4]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" component="h6">
              Ultimos 30 dias
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast30Value[0]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast30Value[0]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast30Value[1]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast30Value[1]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast30Value[2]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast30Value[2]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast30Value[3]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast30Value[3]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.cards}>
            <Paper>
              <Divider></Divider>
              <Typography variant="h4" component="h2">
                {mostLast30Value[4]?.Number}
              </Typography>
              <Divider></Divider>
              <Typography variant="subtitle1" component="h2">
                {mostLast30Value[4]?.Hits} vezes
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
