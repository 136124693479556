import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LotteryForm from "../../components/Form/Lottery/LotteryForm";
import MostForm from "../../components/Form/Lottery/MostForm";
import NumberButtons from "../../components/NumberButtons/NumberButtons";
import MyBetsTable from "../../components/Table/MyBetsTable";
import Paper from "@material-ui/core/Paper";
import api from "../../service/api";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function MultilineTextFields({ setOpenSuccess, setOpenError }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  async function LoadMyBets() {
    const response = await api.get("/lottery/mybets");
    console.log(response.data);

    const newRows = response.data.map((item) =>
      createData(item.hint, item.id, item.lotterykind, item.concourse)
    );

    setRows(newRows);
  }

  function createData(hint, id, lotterykind, concourse) {
    return { hint, id, lotterykind, concourse };
  }

  return (
    <>
      <Paper className={classes.root}>
        <LotteryForm
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
        />
      </Paper>
      <Paper className={classes.root}>
        <NumberButtons
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          loadMyBets={LoadMyBets}
        />
      </Paper>
      <Grid container xs={12} sm={12} spacing={2} style={{ marginTop: "2px" }}>
        <Grid item xs={8} sm={8}>
          <Paper className={classes.root}>
            <MyBetsTable
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
              loadMyBets={LoadMyBets}
              rows={rows}
            />
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Paper className={classes.root} style={{ marginTop: "4px" }}>
            <MostForm
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
