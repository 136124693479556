import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PmtTable from "../../components/Table/PmtTable";
import PmtForm from "../../components/Form/PmtForm";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFE9CC",
    color: "rgba(0, 0, 0, 0.54)",
  },
  appBar: {
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HeartRate({ setOpenSuccess, setOpenError }) {
  const classes = useStyles();

  return (
    <div className={classes.root} id="heartAverage">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <PmtTable
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
              innerTable="Games"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <PmtTable
                setOpenSuccess={setOpenSuccess}
                setOpenError={setOpenError}
                innerTable="Ranking"
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <PmtForm
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
