import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import api from "../../service/api";
import PmtInnerTableGamesFunction from "./PmtInnerTables/PmtInnerTableGames";
import PmtInnerTableRankingFunction from "./PmtInnerTables/PmtInnerTableRanking";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Fragment } from "react";
import dayjs from "dayjs";

function createData(name, id) {
  return { id, name };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {},
});

export default function PmtTableFunction({
  setOpenSuccess,
  setOpenError,
  innerTable,
}) {
  const classes = useStyles();
  const [players, setPlayers] = useState([]);
  const [rows, setRows] = useState([]);
  const [deletedOpen, setDeletedOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    loadPlayers();
  }, []);

  const tempRows = [];
  async function loadPlayers() {
    const response = await api.get("/pmt");
    console.log(response.data);
    setPlayers(response.data);
    response.data.forEach(function (item, i) {
      tempRows.push(createData(item.name, item.id));
    });
    setRows(tempRows);
    setLoading(false);
  }

  async function deleteRow(e) {
    await api
      .delete(`/pmt/${e.id}`)
      .then((response) => {
        if (response.status === 200) {
          loadPlayers();
          setLoading(false);
          setDeletedOpen(true);
        } else {
          console.log(response);
          setLoading(false);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <Paper className={classes.root}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Fragment>
          {innerTable === "Games" ? (
            <PmtInnerTableGamesFunction
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
            />
          ) : (
            <PmtInnerTableRankingFunction
              setOpenSuccess={setOpenSuccess}
              setOpenError={setOpenError}
            />
          )}
        </Fragment>
      )}
    </Paper>
  );
}
