import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import IconButton from "@material-ui/core/IconButton";
import IconDelete from "@material-ui/icons/Delete";
import TablePagination from "@material-ui/core/TablePagination";

const columns = [
  { id: "id", label: "id", minWidth: 35 },
  { id: "hint", label: "Palpites", minWidth: 35 },
  { id: "lotterykind", label: "Loteria", minWidth: 5, maxWidth: 5 },
  { id: "concourse", label: "Nº Concurso", minWidth: 15, maxWidth: 5 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {},
});

export default function MyBetsTableFunction({
  loadMyBets,
  rows,
  setOpenError,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [myBets, setMyBets] = useState([]);

  const [deletedOpen, setDeletedOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [dataLoaded, setDataLoaded] = React.useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      initializeMyBets();
    }
  }, [dataLoaded]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getLotteryName(lotteryKind) {
    if (lotteryKind == 1) {
      return "Lotofácil";
    }
    if (lotteryKind == 2) {
      return "Mega-Sena";
    }
    if (lotteryKind == 3) {
      return "Quina";
    }
    if (lotteryKind == 4) {
      return "Lotomania";
    }
  }

  async function initializeMyBets() {
    try {
      var newRows = loadMyBets()
        .then((response) => {
          setLoading(false);
          setDataLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setOpenError(true);
        });
    } catch (error) {
      console.error("Erro ao carregar apostas:", error);
      setLoading(false);
    }
  }

  async function deleteRow(e) {}

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    hidden={column.label === "id"}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      padding: "10px",
                      display: column.label === "id" ? "none" : "table-cell",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (value > 13) {
                          value = <strong>{value}</strong>;
                        }

                        return (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                display:
                                  column.label === "id" ? "none" : "table-cell",
                              }}
                            >
                              {column.id == "lotterykind"
                                ? getLotteryName(value)
                                : value}
                            </TableCell>
                          </>
                        );
                      })}
                      <TableCell
                        style={{
                          minWidth: 2,
                          maxWidth: 4,
                          padding: "10px",
                        }}
                      >
                        <IconButton
                          onClick={() => deleteRow(row)}
                          color="secondary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <IconDelete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </form>
    </>
  );
}
