import { useEffect } from 'react';
import './App.css';
import TabBar from './components/TabBar/TabBar'
import Login from './pages/Login/Login.js'
import useToken from './useToken';

function App() {
  const { token, setToken } = useToken();

  useEffect(() => {
    console.log(token);
  }, [token]);

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div className="App">
      <header className="App-header">
      <TabBar />
      </header>
    </div>
  );
}

export default App;
