import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Table, TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import api from "../../../service/api";
import IconButton from "@material-ui/core/IconButton";
import IconDelete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Fragment } from "react";
import dayjs from "dayjs";
import { Grid } from "@material-ui/core";

const columns = [
  { id: "id", label: "id", minWidth: 35 },
  { id: "name", label: "Nome", minWidth: 35 },
  { id: "came", label: "Compareceu", minWidth: 25 },
  { id: "enemy", label: "Adversário", minWidth: 25 },
  { id: "popcorn", label: "Pipocou", minWidth: 25 },
];

function createData(name, id) {
  return { id, name };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {},
});

export default function PmtInnerTableGamesFunction({
  setOpenSuccess,
  setOpenError,
  innerTable,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [players, setPlayers] = useState([]);
  const [rows, setRows] = useState([]);
  const [deletedOpen, setDeletedOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [came, setCame] = useState([]);
  const [enemy, setEnemy] = useState([]);
  const [popcorn, setPopCorn] = useState([]);
  const [gameDate, setGameDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleCameChange = (event, itemId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setCame((prevCheckedItems) => [...prevCheckedItems, itemId]);
    } else {
      setCame((prevCheckedItems) =>
        prevCheckedItems.filter((id) => id !== itemId)
      );
    }
  };

  const handleEnemyChange = (event, itemId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setEnemy((prevCheckedItems) => [...prevCheckedItems, itemId]);
    } else {
      setEnemy((prevCheckedItems) =>
        prevCheckedItems.filter((id) => id !== itemId)
      );
    }
  };

  const handlePopcornChange = (event, itemId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setPopCorn((prevCheckedItems) => [...prevCheckedItems, itemId]);
    } else {
      setPopCorn((prevCheckedItems) =>
        prevCheckedItems.filter((id) => id !== itemId)
      );
    }
  };

  const handleSubmit = (event) => {
    createPlayer()
      .then((response) => {
        if (response.status === 200) {
          setOpenSuccess(true);
          console.log("Salvo com sucesso!!!");
        } else {
          setOpenError(true);
          console.log(response);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
      });
  };

  async function createPlayer() {
    try {
      var playerCommand = {
        popcorn: popcorn,
        enemy: enemy,
        came: came,
        gameDate: gameDate,
      };

      var res = await api.put("/pmt/", playerCommand);
      return await res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  useEffect(() => {
    loadPlayers();
  }, []);

  const tempRows = [];
  async function loadPlayers() {
    const response = await api.get("/pmt");
    console.log(response.data);
    setPlayers(response.data);
    response.data.forEach(function (item, i) {
      tempRows.push(createData(item.name, item.id));
    });
    setRows(tempRows);
    setLoading(false);
  }

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    hidden={column.label === "id"}
                    style={{
                      minWidth: column.minWidth,
                      padding: "10px",
                      display: column.label === "id" ? "none" : "table-cell",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (column.id == "id" && value > 13) {
                          value = <strong>{value}</strong>;
                        }
                        if (column.id == "name" && value > 13) {
                          value = <strong>{value}</strong>;
                        }
                        return (
                          <>
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                display:
                                  column.label === "id" ? "none" : "table-cell",
                              }}
                            >
                              {column.id === "came" && (
                                <Checkbox
                                  checked={came.includes(row.id)}
                                  onChange={(event) =>
                                    handleCameChange(event, row.id)
                                  }
                                />
                              )}
                              {column.id === "enemy" && (
                                <Checkbox
                                  checked={enemy.includes(row.id)}
                                  onChange={(event) =>
                                    handleEnemyChange(event, row.id)
                                  }
                                />
                              )}
                              {column.id === "popcorn" && (
                                <Checkbox
                                  checked={popcorn.includes(row.id)}
                                  onChange={(event) =>
                                    handlePopcornChange(event, row.id)
                                  }
                                />
                              )}
                              {column.id !== "came" &&
                                column.id !== "enemy" &&
                                column.id !== "popcorn" &&
                                column.id !== "id" &&
                                value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
      <Grid container style={{ padding: "8px" }}>
        <Grid item xs={6} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Data do jogo"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={6} style={{ marginTop: "8px" }}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Salvar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
