import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import api from "../../service/api";
import useToken from '../../useToken';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function BillsFormFunction({ parentBills, setParentBills, setOpenSuccess, setOpenError }) {
  const classes = useStyles();
  const [billState, setBillState] = React.useState("");
  const [dueDateState, setDueDateState] = React.useState("");
  const [dueNumberState, setDueNumberState] = React.useState("");
  const { token, setToken } = useToken();

  const handleSubmit = (event) => {
    createBill()
      .then((response) => {
        if (response.status === 200) {
          setParentBills(parentBills +1);
          setOpenSuccess(true);
          console.log("Salvo com sucesso!!!");
        } else {
          setOpenError(true);
          console.log(response);
        }
      })
      .catch((error) => {
        setOpenError(true);
        console.log(error);
      });
  };


  const handleDueDate = (event) => {
    setDueDateState(event.target.value);
  };

  const handleDueNumber = (event) => {
    setDueNumberState(event.target.value);
  };


  const handleChangeBill = (event) => {
    setBillState(event.target.value);
  };


  async function createBill() {
    let config = {
      headers: {
          'x-access-token': token,
      }
  }
    var billCommand = {
      bill: billState,
      dueDate: dueDateState,
      dueNumber: dueNumberState
    };

    var res = await api.post("/bills/", billCommand, config);
    return await res;
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="outlined-basic"
            label="Conta"
            variant="outlined"
            onChange={handleChangeBill}
            value={billState}
            type="text"
            fullWidth 
          />
        </Grid>

        <Grid item xs={4} sm={4}>
        </Grid>
        <Grid item xs={2} sm={2}>
          <TextField
            id="outlined-basic"
            label="Venc."
            variant="outlined"
            onChange={handleDueDate}
            value={dueDateState}
            type="number"
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <TextField
            id="outlined-basic"
            label="Parcelas"
            variant="outlined"
            onChange={handleDueNumber}
            value={dueNumberState}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Salvar
          </Button>
        </Grid>
      </Grid>

    </form>
  );
}
