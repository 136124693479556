import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "./../../components/Table/BillsTable";
import Form from "./../../components/Form/BillsForm";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFE9CC",
    color: "rgba(0, 0, 0, 0.54)",
  },
  appBar: {
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HeartRate({parentBills, setParentBills, setOpenSuccess, setOpenError}) {
  const classes = useStyles();

  return (
    <div className={classes.root} id="bills">
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Table parentBills={parentBills} setParentBills={setParentBills} setOpenSuccess={setOpenSuccess} setOpenError={setOpenError}  />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Form parentBills={parentBills} setParentBills={setParentBills} setOpenSuccess={setOpenSuccess} setOpenError={setOpenError}   />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
