import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import './Login.css';
import api from '../../service/api';

async function loginUser(credentials) {
  var res = await api.post("/api/auth/signin", credentials);
  return await res;

}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFE9CC",
    color: "rgba(0, 0, 0, 0.54)",
    height: "100vh",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    overflow: "hidden",
    zIndex: "-1",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function Login({ setToken }) {
  const [username, setUserName] = useState('marcotelles123');
  const [password, setPassword] = useState();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);


  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (typeof password === 'undefined') {
      setPassword('');
    }

    const token = await loginUser({
      username,
      password
    })
      .then((response) => {
        setLoading(false);
        setError(false);
        console.log(response);
        debugger;
        setToken({
          token: response.data.accessToken
        });
      })
      .catch((responseError) => {
        debugger;
        setError(true);
        setLoading(false);
        console.log(responseError);
      });
  }

  return (

    <div className={classes.root} id="heartRate">
      <Paper className={classes.paper}>
        {loading ? <CircularProgress size={24} /> :

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <h1>Please Log In</h1>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-basic"
                label="Username"
                variant="outlined"
                onChange={e => setUserName(e.target.value)}
                value={username}
                type="text"
                error={error}
              />

            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                onChange={e => setPassword(e.target.value)}
                value={password}
                type="password"
                error={error}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Logar
              </Button>
            </Grid>
          </Grid>
        }
      </Paper>

    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};